import { useFlags } from "launchdarkly-react-client-sdk";

import BuildNumber from "shared/components/BuildNumber";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";

import InsuranceRequests from "../../components/insurance/insuranceRequests/InsuranceRequests";
import { DataGridOrdersPage } from "../../components/orders/DataGridOrdersPage";
import { OrdersPage as ResponsiveOrdersPage } from "../../components/orders/OrdersPage";

const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

const OrdersPage = () => {
  const { dataGridExperiment } = useFlags();

  if (isInsurance) {
    return <InsuranceRequests />;
  }

  return (
    <>
      <UnreadRequestsProvider>
        {dataGridExperiment ? <DataGridOrdersPage /> : <ResponsiveOrdersPage />}
      </UnreadRequestsProvider>
      <BuildNumber />
    </>
  );
};

export default OrdersPage;
